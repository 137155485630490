import { Component, OnInit, Inject } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-galery',
  templateUrl: './galery.component.html',
  styleUrls: ['./galery.component.css'],
  providers: [NgbCarouselConfig]
})
export class GaleryComponent implements OnInit {


  constructor(public dialog: MatDialog) {
    
   }
   
   openDialog(pos) {
    this.dialog.open(DialogDataExampleDialog, {

      data: {
        name: `./assets/img/${pos}.jpg`
      },
       height: 'auto',
      width: 'auto',
    });
  }

  ngOnInit() {
  }

}

@Component({
  selector: './galleryDialog',
  styleUrls: ['./galery.component.css'],
  templateUrl: './galleryDialog.html'
})
export class DialogDataExampleDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}