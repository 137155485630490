import { BrowserModule, platformBrowser } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import { ContentComponent } from './components/content/content.component';
import { AboutComponent } from './components/about/about.component';
import { ServicesComponent } from './components/services/services.component';
import { GaleryComponent, DialogDataExampleDialog } from './components/galery/galery.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageComponent } from './home/page/page.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material';
import { GastronomiaComponent } from './components/gastronomia/gastronomia.component';
import { IconsModule } from 'angular-bootstrap-md';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ContentComponent,
    AboutComponent,
    ServicesComponent,
    GaleryComponent,
    FooterComponent,
    PageComponent,
    DialogDataExampleDialog,
    GastronomiaComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    NgbModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatIconModule,
    MatDatepickerModule,
    IconsModule,
    FormsModule,
    MatInputModule,
    MatButtonToggleModule,
    NoopAnimationsModule,
    MatNativeDateModule
  ],
  exports: [GaleryComponent, MatDatepickerModule],
  providers: [],
  entryComponents: [DialogDataExampleDialog],
  bootstrap: [AppComponent, GaleryComponent]
})
export class AppModule { }
