import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { ContentComponent } from './components/content/content.component';
import { FooterComponent } from './components/footer/footer.component';
import { GaleryComponent } from './components/galery/galery.component';
import { ServicesComponent } from './components/services/services.component';



const routes: Routes = [
  {path: 'about', component: AboutComponent},
  {path: 'content', component: ContentComponent},
  {path: 'footer', component: FooterComponent},
  {path: 'galery', component: GaleryComponent},
  {path: 'services', component: ServicesComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
